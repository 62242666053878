import { useCallback, useContext, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import dynamic from 'next/dynamic';
import cn from 'classnames';
import { LanguageContext } from 'context/Language';
import DictionaryContext from 'context/Dictionary/DictionaryContext';
import { articles } from 'api';
import { ArticleTags, Results, Article } from 'types';
import { useTheme, useTranslation } from 'hooks';
import {
  LogoDesktop,
  LogoMobile,
  Search,
  DarkMode,
  Profile,
  SelectDownArrow,
  HeaderCloseIcon,
  Phone,
  Burger,
  HeaderNewsIcon,
} from 'resources';
import { dateFormat } from 'libs';
import { cacheTime } from 'utils';
import dayjs from 'dayjs';

const Container = dynamic(() => import('components/Grid/Container'));
const Icon = dynamic(() => import('components/Icon'));
const LanguageSwitcher = dynamic(() => import('components/LanguageSwitcher'));
const Button = dynamic(() => import('components/Button'));
const Responsive = dynamic(() => import('components/Responsive'));
const Tooltip = dynamic(() => import('components/Tooltip'));
const SearchInput = dynamic(() => import('components/Input/SearchInput'));
const Link = dynamic(() => import('components/Link'));

const Menu = dynamic(() => import('./Menu'));

const Header: React.FC = () => {
  const today = dayjs();
  const tomorrow = dayjs().add(1, 'day');

  const { t } = useTranslation(['header']);
  const { lang } = useContext(LanguageContext);
  const { activeLanguages } = useContext(DictionaryContext);
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const [theme, onChange] = useTheme(
    typeof window !== 'undefined' ? window.localStorage?.getItem('mode') : undefined,
  );

  const [newNews, setNewNews] = useState<number>();

  const { data: articleWarnings } = useQuery<Results<Article>>(
    [
      'warnings',
      {
        tags: ArticleTags.WARNINGS,
        page_size: 1,
        range: [today.subtract(30, 'day').format(dateFormat), tomorrow.format(dateFormat)],
      },
    ],
    articles.getList,
    {
      cacheTime,
    },
  );

  useEffect(() => {
    const news = localStorage.getItem('header-news');
    setNewNews(Number(news));
  }, []);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.localStorage.setItem('mode', `${theme}`);
    }
  }, [theme]);

  const onShowSearch = useCallback(() => {
    setShowSearch((prevState) => {
      if (!prevState) {
        setShowMobileMenu(false);
      }

      return !prevState;
    });
  }, []);

  const onShowMobileMenu = useCallback(() => {
    setShowMobileMenu((prevState) => {
      if (!prevState) {
        setShowSearch(false);
      }

      return !prevState;
    });
  }, []);

  const onCloseNews = useCallback((newsId) => {
    setNewNews(newsId);
    localStorage.setItem('header-news', newsId);
  }, []);

  return (
    <div className="layout__header">
      {articleWarnings?.results?.length > 0 && articleWarnings?.results[0].id !== newNews && (
        <div className="header-news">
          <Container>
            <Link
              href={`/${lang}/news/${articleWarnings?.results[0]?.i18n[lang]?.slug}`}
              className="content"
            >
              <div className="icon">
                <Icon component={HeaderNewsIcon} />
              </div>
              <span>{t('warnings')}</span>
              <p
                className={
                  articleWarnings?.results[0]?.i18n[lang]?.title &&
                  articleWarnings?.results[0]?.i18n[lang]?.title.length > 116
                    ? 'add-points-end'
                    : ''
                }
              >
                {articleWarnings?.results[0]?.i18n[lang]?.title}
              </p>
            </Link>
            <div className="close-news" onClick={() => onCloseNews(articleWarnings?.results[0].id)}>
              <Icon component={HeaderCloseIcon} />
            </div>
          </Container>
        </div>
      )}
      <div className="header-container">
        <Container>
          <div className="header-container__upper-content">
            <Link href={`/${lang}/`} className="logo-box">
              <Responsive until="small-desktop">
                <Icon component={LogoMobile} />
              </Responsive>
              <Responsive>
                <Icon component={LogoDesktop} />
              </Responsive>
            </Link>
            <div className="header-container__call-action">
              <Responsive until="small-desktop">
                <div className="call-box">
                  <Link href={`tel:${t('1310')}`} className="call-box__link">
                    <p className="call-box__text">{t('callCenter')}</p>
                    <div className="call-box__span">
                      <Icon component={Phone} />
                      <span>{t('1310')}</span>
                    </div>
                  </Link>
                </div>
              </Responsive>

              <Responsive>
                <div className={cn('call-box', { 'call-box__opacity': !showSearch })}>
                  <Link href={`tel:${t('1310')}`} className="call-box__link">
                    <p className="call-box__text">{t('callCenter')}</p>
                    <div className="call-box__span">
                      <span>{t('1310')}</span>
                    </div>
                  </Link>
                </div>
              </Responsive>

              <SearchInput
                placeholder={t('enterSearchText')}
                visible={showSearch}
                onChangeVisible={setShowSearch}
              />
              <div className="action-box">
                <div className="action-box__search" onClick={onShowSearch}>
                  <Icon component={showSearch ? HeaderCloseIcon : Search} />
                  <Responsive>
                    <p className="action-box__text">{t('search')}</p>
                  </Responsive>
                </div>

                {/* <Responsive until="small-desktop">
                  <div className="point" />
                </Responsive> */}

                <Responsive>
                  <div
                    className="action-box__night"
                    onClick={() => onChange(theme === 'alternative' ? undefined : 'alternative')}
                  >
                    <Icon component={DarkMode} />
                    <p className="action-box__text">
                      {theme ? t('normalMode') : t('alternativeMode')}
                    </p>
                  </div>
                </Responsive>

                <a
                  href={t('footer.footerCrmURL')}
                  target="_blank"
                  rel="nofollow"
                  className="action-box__account"
                >
                  <Icon component={Profile} />
                  <Responsive>
                    <p className="action-box__text">{t('personalAccount')}</p>
                  </Responsive>
                </a>
              </div>
            </div>
            <Responsive until="small-desktop">
              <Button onClick={onShowMobileMenu} className="header-container__icon-box">
                <Icon component={showMobileMenu ? HeaderCloseIcon : Burger} />
              </Button>
            </Responsive>
          </div>
        </Container>

        <Responsive>
          <div className="header-container__lower-content">
            <Container>
              <div className="navigation">
                <ul className="navigation__links">
                  <Menu />
                </ul>
                <div className="navigation__language-switcher">
                  <div className="select">
                    <span className="language">{t('language')}:</span>
                    <Tooltip
                      trigger="hover"
                      placement="bottom-end"
                      tooltip={<LanguageSwitcher />}
                      onVisibleChange={setTooltipVisible}
                    >
                      <div className="current-language">
                        <span>{t(lang)}</span>
                        {activeLanguages?.length > 0 && (
                          <Icon
                            component={SelectDownArrow}
                            className={cn({ rotate: tooltipVisible })}
                          />
                        )}
                      </div>
                    </Tooltip>
                  </div>
                </div>
              </div>
            </Container>
          </div>
        </Responsive>
      </div>
      <Responsive until="small-desktop">
        <div className={cn('navigation-mobile', { 'is-visible': showMobileMenu })}>
          <div className="navigation-mobile__links">
            <ul className="navigation-mobile__list">
              <Menu isMobile onClick={() => setShowMobileMenu(false)} />
            </ul>
          </div>
          {activeLanguages?.length > 0 && (
            <div className="navigation-mobile__switcher">
              <div className="select">
                <p className="language">{t('language')}</p>
                <LanguageSwitcher isMobile />
              </div>
            </div>
          )}
        </div>
        <div className="navigation-mobile--mask" onClick={() => setShowMobileMenu(false)} />
      </Responsive>
    </div>
  );
};

export default Header;
